document.addEventListener('DOMContentLoaded', function () {
	let copyButton = document.querySelector('.copy-link');
	let copiedNotice = document.querySelector('.copied-notice');
	if (copyButton) {
		copyButton.addEventListener('click', function () {
			let currentUrl = window.location.href;
	
			let tempInput = document.createElement('input');
			tempInput.value = currentUrl;
			document.body.appendChild(tempInput);
	
			tempInput.select();
			tempInput.setSelectionRange(0, 99999); 
	
			document.execCommand('copy');
			document.body.removeChild(tempInput);
	
			copiedNotice.classList.add('active');
	
			setTimeout(function () {
				copiedNotice.classList.remove('active');
			}, 2000);
		});
	}
});