import Rails from "@rails/ujs";
document.addEventListener('turbo:load', function () {
  let popupOverlay  = document.querySelector('#main-popup');
  let closeBtn      = document.querySelector('#close-main-popup');
  let openPopupBtns = document.querySelectorAll('.request-btn');

  if (document.querySelector('#main-popup')) {
    function openPopup() {
      popupOverlay.style.visibility = 'visible';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
    }

    closeBtn.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    openPopupBtns.forEach(function (btn) {
      btn.addEventListener('click', openPopup);
    });
  }
});

document.addEventListener('turbo:load', function () {
  let popupOverlay  = document.querySelector('#popup-offer-request');
  let closeBtn      = document.querySelector('#close-offer-request');
  let openPopupBtns = document.querySelectorAll('.offer-request-btn');

  if (document.querySelector('#popup-offer-request')) {
    function openPopup() {
      popupOverlay.style.visibility = 'visible';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
    }

    closeBtn.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    openPopupBtns.forEach(function (btn) {
      btn.addEventListener('click', openPopup);
    });
  }
});

document.addEventListener('turbo:load', function () {
  let popupOverlay  = document.querySelector('#popup-credit-request');
  let closeBtn      = document.querySelector('#close-credit-request');
  let openPopupBtns = document.querySelectorAll('.credit-request-btn');

  if (document.querySelector('#popup-credit-request')) {
    function openPopup() {
      popupOverlay.style.visibility = 'visible';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
    }

    closeBtn.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    openPopupBtns.forEach(function (btn) {
      btn.addEventListener('click', openPopup);
    });
  }
});

document.addEventListener('turbo:load', function () {
  let popupOverlay  = document.querySelector('#popup-cant-find');
  let closeBtn      = document.querySelector('#close-cant-find');
  let openPopupBtns = document.querySelectorAll('.cant-find-btn');
  let buttonSubmit = document.querySelector('#submit-close-cant-find ')

  if (document.querySelector('#popup-cant-find')) {
    function openPopup() {
      popupOverlay.style.visibility = 'visible';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
    }

    closeBtn.addEventListener('click', closePopup);

    buttonSubmit.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    openPopupBtns.forEach(function (btn) {
      btn.addEventListener('click', openPopup);
    });
  }
});


var brandSelect = document.getElementById("brandSelect");
var modelSelect = document.getElementById("modelSelect");

if (brandSelect) {
  brandSelect.addEventListener("change", function () {
    if (brandSelect.value !== "") {
      modelSelect.disabled = false;
    } else {
      modelSelect.disabled = true;
    }
  });
}



var brandSelectFilter = document.getElementById("brandSelectFilter");
var modelSelectFilter = document.getElementById("modelSelectFilter");

if (brandSelectFilter) {
  brandSelectFilter.addEventListener("change", function () {
    if (brandSelectFilter.value !== "") {
      modelSelectFilter.disabled = false;
    } else {
      modelSelectFilter.disabled = true;
    }
  });
}

var brandSelectForm = document.querySelector(".brandSelect");
var modelSelectForm = document.querySelector(".modelSelect");

if (brandSelectForm) {
  brandSelectForm.addEventListener("change", function () {
    if (brandSelectForm.value !== "") {
      modelSelectForm.disabled = false;
    } else {
      modelSelectForm.disabled = true;
    }
  });
}


if (document.getElementById('form-item-budget')) {
  var initialHeight;
  function changeHeightToAuto() {
    var budgetElement = document.getElementById('form-item-budget');

    if (budgetElement.style.height === 'auto') {
      budgetElement.style.height = initialHeight;
    } else {
      initialHeight = budgetElement.style.height;
      budgetElement.style.height = 'auto';
    }
  }
  document.getElementById('label-budget').addEventListener('click', changeHeightToAuto);
}

if (document.getElementById('form-item-year-filter')) {
  let initialHeightYear;
  function changeHeightToAutoYear() {
    let yearElement = document.getElementById('form-item-year-filter');

    if (yearElement.style.height === 'auto') {
      yearElement.style.height = initialHeightYear;
    } else {
      initialHeightYear = yearElement.style.height;
      yearElement.style.height = 'auto';
    }
  }
  document.getElementById('label-year-filter').addEventListener('click', changeHeightToAutoYear);
}

if (document.querySelectorAll('.hide-click')) {
  document.querySelectorAll('.hide-click').forEach((element) => {
    let initialHeight;
  
    function changeHeightToAuto() {
      let targetElement = element;
  
      if (targetElement.style.height === '230px') {
        targetElement.style.height = initialHeight;
      } else {
        initialHeight = targetElement.style.height;
        targetElement.style.height = '230px';
      }
    }
  
    element.addEventListener('click', changeHeightToAuto);
  });
}


if (document.getElementById('form-item-odometer-filter')) {
  let initialHeightOdometer;
  function changeHeightToAutoOdometer() {
    let odometerElement = document.getElementById('form-item-odometer-filter');

    if (odometerElement.style.height === 'auto') {
      odometerElement.style.height = initialHeightOdometer;
    } else {
      initialHeightOdometer = odometerElement.style.height;
      odometerElement.style.height = 'auto';
    }
  }
  document.getElementById('label-odometer-filter').addEventListener('click', changeHeightToAutoOdometer);
}




document.addEventListener('turbo:load', function () {
  let popupOverlay = document.querySelector('#popup-leasing-offer');
  let closeBtn = document.querySelector('#close-leasing-offer');
  let openPopupBtns = document.querySelectorAll('.leasing-offer-btn');

  if (document.querySelector('#popup-leasing-offer')) {
    function openPopup() {
      popupOverlay.style.visibility = 'visible';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
    }

    closeBtn.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    openPopupBtns.forEach(function (btn) {
      btn.addEventListener('click', openPopup);
    });
  }
});

document.addEventListener('turbo:load', function () {
  let popupOverlay = document.querySelector('#another-car-popup');
  let closeBtn = document.querySelector('#close-another-car-popup');
  let openPopupBtns = document.querySelectorAll('.open-another-car-popup');

  if (document.querySelector('#another-car-popup')) {
    function openPopup() {
      popupOverlay.style.visibility = 'visible';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
    }

    closeBtn.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    openPopupBtns.forEach(function (btn) {
      btn.addEventListener('click', openPopup);
    });
  }
});

// for car show page request popup

document.addEventListener('turbo:load', function () {
  let popupOverlay = document.querySelector('#popup-leave-request');
  let closeBtn = document.querySelector('#close-leave-request');
  let openPopupBtns = document.querySelectorAll('.leave-request');

  if (document.querySelector('#popup-leave-request')) {
    function openPopup() {
      popupOverlay.style.visibility = 'visible';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
    }

    closeBtn.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    openPopupBtns.forEach(function (btn) {
      btn.addEventListener('click', openPopup);
    });
  }
});


var brandSelectAnother = document.querySelector("#brandSelectAnother");
var modelSelectAnother = document.querySelector("#modelSelectAnother");

if (brandSelectAnother) {
  brandSelectAnother.addEventListener("change", function () {
    if (brandSelectAnother.value !== "") {
      modelSelectAnother.disabled = false;
    } else {
      modelSelectAnother.disabled = true;
    }
  });
}

document.addEventListener("DOMContentLoaded", function () {
  if (!localStorage.getItem("cookieConsent")) {
    setTimeout(function () {
      showCookieBanner();
    }, 5000);
  }

  function showCookieBanner() {
    var cookieBlock = document.querySelector('.cookies-block');
    cookieBlock.style.display = "block";
  }

  var closeButtons = document.querySelectorAll('.close-cookies');
  closeButtons.forEach(function (button) {
    button.addEventListener('click', function () {
      var cookieBlock = document.querySelector('.cookies-block');
      cookieBlock.style.display = "none";

      localStorage.setItem("cookieConsent", true);
    });
  });
});

// VIBER POPUP

// document.addEventListener('turbo:load', function () {
//   let popupOverlay = document.querySelector('#viber-popup');
//   let closeBtn = document.querySelector('#close-viber-popup');
//   if (document.querySelector('#viber-popup')) {
//     function closePopup() {
//       popupOverlay.style.visibility = 'hidden';
//     }
//     closeBtn.addEventListener('click', closePopup);
//     popupOverlay.addEventListener('click', function (event) {
//       if (event.target === popupOverlay) {
//         closePopup();
//       }
//     });
//   }
// });

//==============Edit cars images popup===================

document.addEventListener('turbo:load', function () {
  let popupOverlay = document.querySelector('#popup-edit-cars-imgs');
  let closeBtn = document.querySelector('#close-popup-edit-cars-imgs');
  let openPopupBtns = document.querySelectorAll('.open-edit-cars-imgs-popup');

  if (document.querySelector('#popup-edit-cars-imgs')) {
    function openPopup() {
      popupOverlay.style.visibility = 'visible';
      document.body.style.overflow = 'hidden';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
      document.body.style.overflow = '';
    }

    closeBtn.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    openPopupBtns.forEach(function (btn) {
      btn.addEventListener('click', openPopup);
    });
  }
});

document.addEventListener('turbo:load', function () {
  let deleteButtons = document.querySelectorAll('.delete-img');

  deleteButtons.forEach(function (button) {
    button.addEventListener('click', function () {
      let url = button.dataset.url;
      let carSlug = button.dataset.carSlug;

      Rails.ajax({
        type: "DELETE",
        url: `/live-auction/${carSlug}/delete_image`,
        data: JSON.stringify({ url: url }),
        success: (data) => {
          button.closest('.image').remove();
        },
        error: (error) => {
          console.error('Грешка при изтриване на изображението:', error);
          alert('Грешка при изтриване на изображението.');
        }
      });
    });
  });
});
