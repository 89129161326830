var currentURL = window.location.href;

if ( currentURL.includes("/catalogue") ) {
  $(function () {
    var rangeSlider = $(".js-range-slider");

    rangeSlider.ionRangeSlider({
      skin: "big",
      min: 0,
      max: 10000,
      from: 1000,
      to: 9000,
      type: 'double',
      step: 10,
      hide_min_max: true,
      onStart: function (data) {
        $('#slider1Value').val(data.from);
        $('#slider2Value').val(data.to);
      },
      onChange: function (data) {
        $('#slider1Value').val(data.from);
        $('#slider2Value').val(data.to);
      }
    });

    $('#slider1Value').on('input', function () {
      var val = $(this).val();
      rangeSlider.data("ionRangeSlider").update({
        from: val
      });
    });

    $('#slider2Value').on('input', function () {
      var val = $(this).val();
      rangeSlider.data("ionRangeSlider").update({
        to: val
      });
    });
  });

  $(function () {
    var rangeSlider = $(".js-range-slider-year");
    var year_start = $("#slider1Value2")[0].value;
    var year_end = $("#slider2Value2")[0].value;

    rangeSlider.ionRangeSlider({
      skin: "big",
      min: 2000,
      max: 2024,
      from: year_start || 2000,
      to: year_end || 2024,
      type: 'double',
      step: 1,
      hide_min_max: true,
      onStart: function (data) {
        $('#slider1Value2').val(data.from);
        $('#slider2Value2').val(data.to);
      },
      onChange: function (data) {
        $('#slider1Value2').val(data.from);
        $('#slider2Value2').val(data.to);
      }
    });

    $('#slider1Value2').on('input', function () {
      var val = $(this).val();
      rangeSlider.data("ionRangeSlider").update({
        from: val
      });
    });

    $('#slider2Value2').on('input', function () {
      var val = $(this).val();
      rangeSlider.data("ionRangeSlider").update({
        to: val
      });
    });
  });

  // ODOMETR RANGE
  // $(function () {
  //   var rangeSlider = $(".js-range-slider-odometer");
  //   var odometer_start = $("#slider1Value3")[0].value;
  //   var odometer_end = $("#slider2Value3")[0].value;

  //   rangeSlider.ionRangeSlider({
  //     skin: "big",
  //     min: 12500,
  //     max: 400000,
  //     from: odometer_start || 12500,
  //     to: odometer_end || 400000,
  //     type: 'double',
  //     step: 1000,
  //     hide_min_max: true,
  //     onStart: function (data) {
  //       $('#slider1Value3').val(data.from);
  //       $('#slider2Value3').val(data.to);
  //     },
  //     onChange: function (data) {
  //       $('#slider1Value3').val(data.from);
  //       $('#slider2Value3').val(data.to);
  //     }
  //   });

  //   $('#slider1Value3').on('input', function () {
  //     var val = $(this).val();
  //     rangeSlider.data("ionRangeSlider").update({
  //       from: val
  //     });
  //   });

  //   $('#slider2Value3').on('input', function () {
  //     var val = $(this).val();
  //     rangeSlider.data("ionRangeSlider").update({
  //       to: val
  //     });
  //   });
  // });
}
