document.addEventListener("DOMContentLoaded", function () {
  const buttons = document.querySelectorAll('.filter-wrapper p');
  const items   = document.querySelectorAll('.leasing-item-wrapper');
  if (buttons) {
    buttons.forEach(button => {
      button.addEventListener('click', function () {
        const targetClass = button.classList[1].split('-')[1];

        buttons.forEach(btn => btn.classList.remove('active'));
        button.classList.add('active');

        items.forEach(item => item.classList.remove('active'));
        items.forEach(item => {
          if (item.classList.contains(`model-${targetClass}`)) {
            item.classList.add('active');
          }
        });
      });
    });
  }
});

