var stages = document.querySelectorAll('.stage');
var progressRange = document.querySelector('.progress-range');
var windowHeight = window.innerHeight;

function setActiveStage() {
  var middleOfScreen = window.scrollY + windowHeight / 2;

  stages.forEach(function(stage, index) {
    var stageTop = stage.offsetTop;
    var stageBottom = stageTop + stage.clientHeight;

    if (middleOfScreen >= stageTop && middleOfScreen <= stageBottom) {
      stages.forEach(function(s) {
        s.classList.remove('active');
        s.querySelector('.stage-value .h5-medium').style.color = '#46484C';
        s.querySelector('.stage-value .main-text-medium').style.color = '#46484C';
      });

      stage.classList.add('active');

      // Змінюємо стиль 'progressRange' для відображення прогресу анімації
      if (window.innerWidth > 1200) {
        var width = (index + 1) * (100 / stages.length) - 3.5;
        progressRange.style.width = `${width}%`;
      } else {
        var height = (index + 1) * (100 / stages.length) - 3.5;
        progressRange.style.height = `${height}%`;
      }

      // Змінюємо кольори тексту у попередніх та наступних елементах 'stage'
      for (var i = 0; i < stages.length; i++) {
        var textColor = i <= index ? '#CED1D3' : '#46484C';
        stages[i].querySelector('.stage-value .h5-medium').style.color = textColor;
        stages[i].querySelector('.stage-value .main-text-medium').style.color = textColor;
      }
    }
  });
}

window.addEventListener('scroll', function() {
  setActiveStage();
});

window.addEventListener('resize', function() {
  windowHeight = window.innerHeight;
  setActiveStage();
});
