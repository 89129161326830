import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="timer"
export default class extends Controller {
  static targets = [ "car" ]

  connect() {
    if (this.data.get("page") == "show") {
      this.show(this.data.get("date"));
    }

    if (this.data.get("page") == "index") {
      this.index(this.data.get("date"));
    }
  }

  show(dateString) {
    let { days, hours, minutes, seconds } = calculateMetrics(dateString);

    document.getElementById("days").innerHTML = days + "d";
    document.getElementById("hours").innerHTML = hours + "h";
    document.getElementById("minutes").innerHTML = minutes + "m";
    document.getElementById("seconds").innerHTML = seconds + "s";
    document.getElementById("daysmob").innerHTML = days + "d";
    document.getElementById("hoursmob").innerHTML = hours + "h";
    document.getElementById("minutesmob").innerHTML = minutes + "m";
    document.getElementById("secondsmob").innerHTML = seconds + "s";

    setTimeout(() => this.show(dateString), 1000);
  }

  index(dateString) {
    let { days, hours, minutes, seconds } = calculateMetrics(dateString);

    this.carTarget.innerHTML = `${days}d:${hours}h:${minutes}m:${seconds}s`;

    setTimeout(() => this.index(dateString), 1000);
  }
}

function calculateMetrics(dateString) {
  var parts = dateString.split(/[- :]/);
  var dateObject = new Date(Date.UTC(parts[0], parts[1]-1, parts[2], parts[3], parts[4], parts[5]));
  var now = new Date();

  var timeDifferenceInSeconds = Math.floor((dateObject - now) / 1000);

  var days = Math.floor(timeDifferenceInSeconds / (24 * 3600));
  var hours = Math.floor((timeDifferenceInSeconds % (24 * 3600)) / 3600);
  var minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60);
  var seconds = timeDifferenceInSeconds % 60;

  return { days, hours, minutes, seconds }
}
