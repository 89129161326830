var startTime = parseInt(sessionStorage.getItem('startTime')) || new Date().getTime();
var popupShownTimes = parseInt(sessionStorage.getItem('popupShownTimes')) || 0;

function updateTimer() {
    var currentTime = new Date().getTime();
    var elapsedTime = currentTime - startTime;

    sessionStorage.setItem('startTime', startTime.toString());
    sessionStorage.setItem('popupShownTimes', popupShownTimes.toString());

    var mainPopup = document.getElementById('main-popup');
    // var viberPopup = document.getElementById('viber-popup');

    var elapsedSeconds = Math.floor(elapsedTime / 1000);

    if (popupShownTimes === 0 && elapsedSeconds >= 20) {
        mainPopup.style.visibility = "visible";
        popupShownTimes++;
        sessionStorage.setItem('popupShownTimes', popupShownTimes.toString());
    } else if (popupShownTimes === 1 && elapsedSeconds >= 100) {
        mainPopup.style.visibility = "visible";
        popupShownTimes++;
        sessionStorage.setItem('popupShownTimes', popupShownTimes.toString());
    }

    // if (Math.floor(elapsedTime / 1000) === 40) {
    //     viberPopup.style.visibility = "visible";
    // }

    // console.log(elapsedSeconds);
}

setInterval(updateTimer, 1000);

// var startTime = parseInt(sessionStorage.getItem('startTime')) || new Date().getTime();
// var popupShown = sessionStorage.getItem('popupShown') === 'true';

// function updateTimer() {
//     var currentTime = new Date().getTime();
//     var elapsedTime = currentTime - startTime;

//     sessionStorage.setItem('startTime', startTime.toString());

//     var mainPopup = document.getElementById('main-popup');

//     var elapsedSeconds = Math.floor(elapsedTime / 1000);

//     if (!popupShown && elapsedSeconds >= 50) {
//         mainPopup.style.visibility = "visible";
//         sessionStorage.setItem('popupShown', 'true');
//         popupShown = true;
//     }
// }

// setInterval(updateTimer, 1000);
