document.addEventListener("DOMContentLoaded", function() {
  const inputs = document.querySelectorAll("input[type='tel']");

  const allowedCountries = ["at", "be", "bg", "hr", "cy", "cz", "dk",
    "ee", "es", "fi", "fr", "de", "gr", "hu", "ie", "it", "lv",
    "lt", "lu", "mk", "mt", "nl", "pl", "pt", "ro", "rs", "se",
    "sk", "si", 'tr'];

  const validateBulgarianNumber = (input, iti, errorMessage) => {
    if (iti.getSelectedCountryData().iso2 === 'bg') {
      let inputValue = input.value;
      const numericValue = inputValue.replace(/[^\d+]/g, '');
      const limitedValue = numericValue.slice(0, 9);

      input.value = limitedValue;
      if (/^[1-9]\d{8}$/.test(limitedValue) || limitedValue === '') {
        input.classList.remove('invalid');
        input.closest('form').querySelector('input[type="submit"]').removeAttribute('disabled');
        input.style.borderColor = "#e5e7e8";
        errorMessage.style.display = 'none';
      } else {
        input.classList.add('invalid');
        input.closest('form').querySelector('input[type="submit"]').setAttribute('disabled', 'disabled');
        input.style.borderColor = "red";
        errorMessage.style.display = 'block';
      }
    }
  };

  const resetValidation = (input, errorMessage) => {
    input.classList.remove('invalid');
    input.closest('form').querySelector('input[type="submit"]').removeAttribute('disabled');
    input.style.borderColor = "#e5e7e8";
    errorMessage.style.display = 'none';
  };

  inputs.forEach(function(input) {
    const iti = window.intlTelInput(input, {
      initialCountry: 'auto',
      hiddenInput: "phone",
      onlyCountries: allowedCountries,
      geoIpLookup: callback => {
        fetch('https://ipapi.co/json')
          .then(res => res.json())
          .then(data => {
            const countryCode = data.country.toLowerCase();
            return allowedCountries.includes(countryCode) ? countryCode : 'bg';
          })
          .then(countryCode => callback(countryCode))
          .catch(() => callback('bg'));
      },
      separateDialCode: true,
      utilsScript: '//cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.3/js/utils.js',
    });

    const errorMessage = document.createElement('span');
    errorMessage.className = 'error-message';
    errorMessage.textContent = 'Телефонният номер трябва да съдържа точно 9 цифри и не може да започва с 0.';
    errorMessage.style.maxWidth = `${input.offsetWidth}px`;
    input.parentNode.insertAdjacentElement('afterend', errorMessage);

    input.addEventListener('input', () => validateBulgarianNumber(input, iti, errorMessage));

    input.addEventListener('countrychange', () => {
      const countryData = iti.getSelectedCountryData();
      if (countryData.iso2 === 'bg') {
        input.addEventListener('input', () => validateBulgarianNumber(input, iti, errorMessage));
        validateBulgarianNumber(input, iti, errorMessage);
      } else {
        input.removeEventListener('input', () => validateBulgarianNumber(input, iti, errorMessage));
        resetValidation(input, errorMessage);
      }
    });
  });
});
