var currentURL = window.location.href;

if (currentURL.includes("/admin")) {
  document.addEventListener('change', function(event) {
    const target = event.target;
    if (target && target.matches('.uploader-box input[type="file"]')) {
      const file = target.files[0];
      const uploaderBox = target.closest('.uploader-box');
      const imagePreview = uploaderBox.querySelector('.image-preview');

      if (file) {
        const reader = new FileReader();

        reader.addEventListener('load', function() {
          imagePreview.innerHTML = ''; // Clear previous preview
          const imgElement = document.createElement('img');
          imgElement.src = reader.result;
          imgElement.title = 'image';
          imgElement.alt = 'image';
          imagePreview.appendChild(imgElement);
        });

        reader.readAsDataURL(file);
      }
    }
  });

  // Додатково можна використовувати MutationObserver для виявлення появи нових елементів
  const observer = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
      mutation.addedNodes.forEach(function(node) {
        if (node.nodeType === Node.ELEMENT_NODE && node.matches('.uploader-box')) {
          const fileInput = node.querySelector('input[type="file"]');
          fileInput.addEventListener('change', function() {
            // Опціонально можна додатково обробити подію change для нового блоку
          });
        }
      });
    });
  });

  // Спостерігати за додаванням нових елементів до body
  observer.observe(document.body, { childList: true, subtree: true });
}
