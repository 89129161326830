document.addEventListener("DOMContentLoaded", function() {
  const forms = [
    { formId: "search-form", errorId: "error-message" },
    { formId: "search-form-home", errorId: "error-message" },
    { formId: "search-form-mobile", errorId: "error-message-mobile" }
  ];

  forms.forEach(({ formId, errorId }) => {
    const form = document.getElementById(formId);
    const errorMessage = document.getElementById(errorId);

    if (form) {
      form.addEventListener("submit", function(event) {
        event.preventDefault();

        const query = form.querySelector('input[name="query"]').value;

        fetch(form.action + "?query=" + encodeURIComponent(query), {
          method: "GET",
          headers: {
            "Accept": "application/json"
          }
        })
          .then(response => response.json())
          .then(data => {
            if (data.redirect_url) {
              window.location.href = data.redirect_url;
            } else if (data.error) {
              errorMessage.textContent = data.error;
              errorMessage.classList.remove("hidden");
            }
          })
          .catch(error => {
            console.error("Error:", error);
          });
      });
    }
  });
});
