import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['brand', 'model', 'vehicleType']

  connect() {
    this.cleanUrl();
  }

  changeVehicleType(event) {
    const vehicleType = event.target.value;
    this.fetchAndUpdate(`/change-vehicle-type?vehicle_type=${vehicleType}`);
  }

  updateCarBrands(event) {
    const brandId = this.brandTarget.value;
    const brandModelName = event.params.brandModelName;
    const vehicleType = this.vehicleTypeTargets.find(target => target.checked).value;
    this.fetchAndUpdate(`/update-car-brands?brand_id=${brandId}&brand_model_name=${brandModelName}&vehicle_type=${vehicleType}`);
  }

  updateCarModels(event) {
    const brandId = this.brandTarget.value;
    const brandModelName = this.modelTarget.value;
    const vehicleType = this.vehicleTypeTargets.find(target => target.checked).value;
    this.fetchAndUpdate(`/update-car-models?brand_id=${brandId}&brand_model_name=${brandModelName}&vehicle_type=${vehicleType}`);
  }

  fetchAndUpdate(url) {
    Rails.ajax({
      type: "get",
      url: url,
      accept: 'text/vnd.turbo-stream.html',
      success: (html) => { Turbo.renderStreamMessage(html) },
      error: (error) => { console.error('Error:', error); }
    });
  }

  cleanUrl() {
    const params = new URLSearchParams(window.location.search);
    const keys = [...params.keys()];

    keys.forEach(key => {
      const value = params.get(key);
      if (value === '' || value === '0') {
        params.delete(key);
      }
    });

    const newUrl = window.location.pathname + (params.toString() ? `?${params.toString()}` : '');
    window.history.replaceState(null, '', newUrl);
  }

  getMetaContent(name) {
    return document.querySelector(`meta[name="${name}"]`).getAttribute('content');
  }
}
