document.addEventListener('DOMContentLoaded', function () {
  const stages        = document.querySelectorAll('.stage');
  const progressRange = document.querySelector('.progress-range');
  const screenWidth   = window.innerWidth;

  if (stages) {
    stages.forEach((stage, index) => {
      stage.addEventListener('mouseenter', function () {
        const previousStages = Array.from(stages).slice(0, index + 1);
        const nextStages = Array.from(stages).slice(index + 1);

        if (screenWidth > 1200) {
          const width = (index + 1) * (100 / stages.length) - 3.5;
          progressRange.style.width = `${width}%`;
        } else {
          const height = (index + 1) * (100 / stages.length) - 3.5;
          progressRange.style.height = `${height}%`;
        }

        previousStages.forEach(prevStage => {
          prevStage.querySelector('.stage-value .h5-medium').style.color = '#46484C';
          prevStage.querySelector('.stage-value .main-text-medium').style.color = '#CED1D3';
        });

        nextStages.forEach(stage => {
          stage.querySelector('.stage-value .h5-medium').style.color = '';
          stage.querySelector('.stage-value .main-text-medium').style.color = '';
        });

        stages.forEach((stage) => {
          stage.classList.remove('active');
        });
        stage.classList.add('active');
      });
    });
  }
});
