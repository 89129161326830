import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

document.addEventListener('DOMContentLoaded', () => {
	Fancybox.bind("[data-fancybox]", {
		Image: {
			zoom: true, 
			Panzoom: {
				maxScale: 5, 
				minScale: 2, 
				zoom: true, 
			}
		},
		clickContent: function (current, event) {
			if (current.type === "image") {
				const instance = Fancybox.getInstance();
				const panzoom = instance.Panzoom;

				if (panzoom) {
					panzoom.zoomIn(); 
				}

				return false; 
			}
		},
	});
});