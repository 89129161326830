
document.addEventListener('turbo:load', function () {
	var filterBtn = document.querySelector('.filters-open');
	var filter = document.querySelector('.filters');
	var closeBtn = document.querySelector('.close-filters');
	var darkenOverlay = document.querySelector('.darken');
	var body = document.querySelector('body'); 

	if (filterBtn) {
		filterBtn.addEventListener('click', function (event) {
			event.stopPropagation();
			filter.classList.add('active');
			darkenOverlay.style.display = 'block';
			body.style.overflow = 'hidden'; 
		});

		closeBtn.addEventListener('click', function (event) {
			event.stopPropagation();
			filter.classList.remove('active');
			darkenOverlay.style.display = 'none';
			body.style.overflow = ''; 
		});

		document.addEventListener('click', function (event) {
			var isClickInsideFilter = filter.contains(event.target);
			var isClickOnFilterBtn = filterBtn.contains(event.target);
			if (!isClickInsideFilter && !isClickOnFilterBtn) {
				filter.classList.remove('active');
				darkenOverlay.style.display = 'none';
				body.style.overflow = ''; 
			}
		});
	}
});


document.addEventListener("DOMContentLoaded", function () {
	var leftSideWrapper = document.querySelector('.left-side-wrapper');
	var carsCatalogue = document.querySelector('.cars-catalogue');

	if (leftSideWrapper && carsCatalogue) {
		leftSideWrapper.style.height = carsCatalogue.offsetHeight + 'px';
	}
});

// document.addEventListener('DOMContentLoaded', function() {
//     var scrollPosition = sessionStorage.getItem('scrollPosition');
//     if (scrollPosition !== null) {
//         window.scrollTo(0, parseInt(scrollPosition));
//         sessionStorage.removeItem('scrollPosition');
//     }
// });

// document.addEventListener('click', function(event) {
//     var target = event.target;
//     if (target.classList.contains('scroll-back')) {
//         sessionStorage.setItem('scrollPosition', window.scrollY);
//     }
// });

document.addEventListener('DOMContentLoaded', function() {
    var scrollPosition = sessionStorage.getItem('scrollPosition');
    if (scrollPosition !== null) {
        window.scrollTo(0, parseInt(scrollPosition));
        sessionStorage.removeItem('scrollPosition');
    }
});

document.addEventListener('click', function(event) {
    var target = event.target;
    if (target.classList.contains('scroll-back')) {
        sessionStorage.setItem('scrollPosition', window.scrollY);
    }
});

document.addEventListener('submit', function(event) {
    var form = event.target;
    if (form.id === 'form-filter') {
        var carPositionElement = document.getElementById('car-position-scroll');
        if (carPositionElement) {
            var position = carPositionElement.getBoundingClientRect().top + window.scrollY;
            sessionStorage.setItem('scrollPosition', position);
        }
    }
});
