document.addEventListener("turbo:load", function () {
  const wrappers = document.querySelectorAll(".video-wrapper");
  const paginationItems = document.querySelectorAll(".pagination-list-item.videopag");
  const previousPageBtn = document.querySelector(".previous-page.videopag");
  const nextPageBtn = document.querySelector(".next-page.videopag");
  if (previousPageBtn) {
    let currentPageIndex = 0;

    function showPreviousPage() {
      if (currentPageIndex > 0) {
        currentPageIndex--;
        showPage(currentPageIndex);
      }
    }

    function showNextPage() {
      if (currentPageIndex < wrappers.length - 1) {
        currentPageIndex++;
        showPage(currentPageIndex);
      }
    }

    function showPage(index) {
      wrappers.forEach(function (wrapper, i) {
        if (index === i) {
          wrapper.classList.add("active");
        } else {
          wrapper.classList.remove("active");
        }
      });

      paginationItems.forEach(function (paginationItem) {
        paginationItem.classList.remove("active");
      });
      paginationItems[index].classList.add("active");
    }

    paginationItems.forEach(function (paginationItem, index) {
      paginationItem.addEventListener("click", function () {
        currentPageIndex = index;
        showPage(currentPageIndex);
      });
    });

    previousPageBtn.addEventListener("click", showPreviousPage);

    nextPageBtn.addEventListener("click", showNextPage);

    showPage(currentPageIndex);
  }
});



document.addEventListener("turbo:load", function () {
  const wrappers = document.querySelectorAll(".gallery");
  const paginationItems = document.querySelectorAll(".pagination-list-item.photopag");
  const previousPageBtn = document.querySelector(".previous-page.photopag");
  const nextPageBtn = document.querySelector(".next-page.photopag");
  if (previousPageBtn) {
    let currentPageIndex = 0;

    function showPreviousPage() {
      if (currentPageIndex > 0) {
        currentPageIndex--;
        showPage(currentPageIndex);
      }
    }

    function showNextPage() {
      if (currentPageIndex < wrappers.length - 1) {
        currentPageIndex++;
        showPage(currentPageIndex);
      }
    }

    function showPage(index) {
      wrappers.forEach(function (wrapper, i) {
        if (index === i) {
          wrapper.classList.add("active");
        } else {
          wrapper.classList.remove("active");
        }
      });

      paginationItems.forEach(function (paginationItem) {
        paginationItem.classList.remove("active");
      });
      paginationItems[index].classList.add("active");
    }

    paginationItems.forEach(function (paginationItem, index) {
      paginationItem.addEventListener("click", function () {
        currentPageIndex = index;
        showPage(currentPageIndex);
      });
    });

    previousPageBtn.addEventListener("click", showPreviousPage);

    nextPageBtn.addEventListener("click", showNextPage);

    showPage(currentPageIndex);
  }
});
